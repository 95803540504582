<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0">Coordinadores de grupos</p>
        <v-btn
          color="primary"
          elevation="0"
          @click="modalAdd"
          v-if="searchInArray(actions, 'Agregar')"
        >
          <v-icon left light> mdi-plus </v-icon>
          <span class="d-none d-sm-flex">Agregar coordinador</span>
          <span class="d-flex d-sm-none">Agregar</span>
        </v-btn>
      </div>
    </v-banner>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
              v-if="searchInArray(actions, 'Ver')"
              :headers="data.headers"
              :items="data.data"
              :item-per-page="5"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
            >
              <template v-slot:top>
                <v-toolbar flat height="50">
                  <v-toolbar-title class="text-h5">
                    Lista de Coordinadores
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <v-icon>mdi-file-excel</v-icon>
                          Descargar CSV
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <!-- Actions -->
              <template v-slot:[`item.groups`]="{ item }">
                <v-chip v-for="(group, index) in item.groups_obj" :key="index">
                  {{ group.group }}
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <template v-if="searchInArray(actions, 'Editar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="
                          selectCoordinator(item);
                          modalUpdate();
                        "
                        color="amber"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
                <template v-if="searchInArray(actions, 'Eliminar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectCoordinator(item);
                          modalDelete();
                        "
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
                <template v-if="searchInArray(actions, 'Activar/Desactivar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectCoordinator(item);
                          changeStatus();
                        "
                        :color="
                          item.status == 1
                            ? 'light-blue darken-3'
                            : 'blue-grey darken-1'
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          item.status == 1
                            ? "mdi-eye-outline"
                            : "mdi-eye-off-outline"
                        }}
                      </v-icon>
                    </template>
                    <span>
                      {{ item.status == 1 ? "Activar" : "Desactivar" }}
                    </span>
                  </v-tooltip>
                </template>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="getCoordinators">
                  Recargar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modales -->
    <AddCoordinator
      ref="mdlAdd"
      :coordinator="newCoordinator"
      :clean="cleanAdd"
      :groups="donationGroups"
    ></AddCoordinator>
    <UpdateCoordinator
      ref="mdlUpdate"
      :coordinator="slcCoordinator"
      :clean="cleanUpdate"
      :groups="donationGroups"
    ></UpdateCoordinator>
    <v-dialog v-model="dialogDelete" max-width="450px">
      <v-card>
        <v-card-title class="headline">Advertencia</v-card-title>
        <v-card-text class="text-center">
          <p class="mb-0">¿Está seguro de eliminar este elemento?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">
            Cancelar
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="deleteCoordinatorConfirm"
            :loading="isLoading"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddCoordinator from "./AddCoordinator";
import UpdateCoordinator from "./UpdateCoordinator";

export default {
  name: "coordinator",
  components: {
    AddCoordinator,
    UpdateCoordinator,
  },
  data: () => ({
    data: {
      load: true,
      headers: [
        {
          text: "Coordinador",
          value: "name",
        },
        {
          text: "Correo electrónico",
          value: "email",
        },
        {
          text: "Teléfono",
          value: "phone",
        },
        {
          text: "Grupos",
          value: "groups",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
        },
      ],
      data: [],
    },
    donationGroups: [],
    newCoordinator: {},
    slcCoordinator: {},
    dialogDelete: false,
    isLoading: false,
    search: "",
  }),
  async mounted() {
    this.getCurrentActions("Coordinador");
    await this.getDonationGroups();
    await this.getCoordinators();
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    //funcionalidad
    getDonationGroups: async function() {
      await this.$http
        .get(this.api + "group")
        .then(({ data }) => {
          this.donationGroups = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        });
    },

    getCoordinators: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + "donor_group/all/coordinators")
        .then(({ data }) => {
          data.forEach((item) => {
            let myIdGroups = item.id_groups.split(",");
            let myGroups = [];
            this.donationGroups.forEach((group) => {
              myIdGroups.forEach((id) => {
                if (parseInt(id) == group.id_group) {
                  myGroups.push(group);
                }
              });
            });
            item.groups_obj = myGroups;
          });
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    selectCoordinator(coordinator) {
      this.slcCoordinator = coordinator;
    },

    cleanAdd() {
      this.newCoordinator = {};
      this.getCoordinators();
    },

    modalAdd() {
      this.$refs.mdlAdd.changeModal();
    },

    modalUpdate() {
      this.$refs.mdlUpdate.changeModal();
    },

    cleanUpdate() {
      this.slcCoordinator = {};
      this.getCoordinators();
    },

    modalDelete() {
      this.dialogDelete = true;
    },

    deleteCoordinatorConfirm() {
      this.isLoading = true;
      this.$http
        .delete(this.api + "donor/" + this.slcCoordinator.id_donor)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeDelete();
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
          this.closeDelete();
        });
    },

    changeStatus() {
      this.$http
        .get(this.api + "donor/status/" + this.slcCoordinator.id_donor)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.slcCoordinator = {};
      });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      img: "getImg",
      actions: "getCurrentActions",
    }),
  },
};
</script>

<style>
.v-tooltip__content {
  pointer-events: initial;
}
</style>
